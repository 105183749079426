<template>
  <master-layout
    smallTitle="Farmacie nella tua"
    bigTitle="Area Geografica"
    :showInfoPopHover="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
  >
    <ion-row class="cta ion-justify-content-center ion-padding-top"
      ><ion-col size="auto">
        <button class="p-d button-primary" @click="openModalNewClient">
          Aggiungi cliente +
        </button>
      </ion-col>
    </ion-row>
    <ion-grid class="main-container">
      <ion-row class="searchbar">
        <ion-col>
          <ion-searchbar
            placeholder="Ricerca cliente esistente..."
            v-model="filter"
            autocomplete="on"
            clear-icon
            search-icon
          ></ion-searchbar>
        </ion-col>
      </ion-row>
      <FiltroClienti
        :showFiltroAgente="userData.role == 'Agente' ? false : true"
        @update:anagProspectFilters="handleFiltroCLientiChange"
        @reset:anagProspectFilters="handleFiltroClientiReset"
        @cerca:anagProspectFilters="handleFiltroClientiCerca"
      ></FiltroClienti>
      <ion-row
        class="items ion-padding-horizontal ion-margin-top"
        v-for="item of items"
        :key="item.id"
        @click="gotoDettagliCliente(item)"
      >
        <ion-col>
          <ion-row>
            <span class="font-bold"> {{ item.ragioneSociale }} </span>
          </ion-row>
          <ion-row> {{ item.citta }} {{ item.provincia }} </ion-row>
        </ion-col>
        <ion-col class="ion-text-end ion-padding-top" size="2">
          <ion-icon :icon="call"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-row class="cta ion-justify-content-center"
      ><ion-col size="auto">
        <button
          :disabled="loading"
          type="button"
          class="p-d button-primary"
          @click="askForMoreResults"
        >
          {{ loading ? "Caricamento..." : "Carica più risultati" }}
        </button>
      </ion-col>
    </ion-row>
  </master-layout>
</template>

<script>
import {
  modalController,
  IonButton,
  IonFooter,
  IonToolbar,
  IonSearchbar,
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { call } from "ionicons/icons";
import ModalActualClient from "./Modals/ModalActualClient.vue";
import ModalNewAzienda from "./Modals/ModalNewAzienda.vue";
import GradientProgressBar from "@/components/GradientProgressBar.vue";
import FiltroClienti from "@/components/Nuovi/FiltroClienti.vue";
import { useGeneralStore } from "@/stores/useGeneralStore.js";
import ApiService from "../common/service.api";
import { mapGetters } from "vuex";
export default {
  name: "AnagraficaClientiFarmacieAssegnate",
  components: {
    IonButton,
    IonFooter,
    IonToolbar,
    IonSearchbar,
    IonCol,
    IonGrid,
    IonRow,
    IonIcon,
    GradientProgressBar,
    IonSelect,
    IonSelectOption,
    FiltroClienti,
  },
  data() {
    return {
      call,
      filter: "",
      items: [],

      INFOPOPOVER_MAP: {
        Yoube: "Una lista di possibili clienti da contattare.",
        default: "Una lista di possibili clienti da contattare.",
      },
      currentResults: 0,
      loading: false,
      currentFiltroClienti: {
        regione: null,
        provincia: null,
        citta: null,
        agente: null,
      },
    };
  },
  mounted() {
    const generalStore = useGeneralStore();

    this.currentFiltroClienti = generalStore.youbeFilter;

    this.getData();
  },
  methods: {
    async openModalNewClient() {
      const modal = await modalController.create({
        component: ModalNewAzienda,
        cssClass: "my-custom-class",
        componentProps: { aziende: this.aziende },
      });

      return modal.present();
    },
    handleFiltroClientiCerca() {
      this.currentResults = 0;
      this.getData();
    },
    handleFiltroClientiReset() {
      this.currentResults = 0;
      this.filter = "";
    },
    handleFiltroCLientiChange(filtro) {
      this.currentFiltroClienti = filtro;
    },

    async getData() {
      this.loading = true;
      const endpoint = "Ecommerce/GetAnagsProspect/";
      const requestData = {
        take: 10,
        skip: 0,
        ricerca: this.filter ? this.filter : "",
        regione:
          this.currentFiltroClienti.regione === "all"
            ? ""
            : this.currentFiltroClienti.regione,
        provincia:
          this.currentFiltroClienti.provincia === "all"
            ? ""
            : this.currentFiltroClienti.provincia,
        citta:
          this.currentFiltroClienti.citta === "all"
            ? ""
            : this.currentFiltroClienti.citta,
        idAgente:
          this.currentFiltroClienti.agente !== null
            ? this.currentFiltroClienti.agente
            : 0,
      };

      try {
        const res = await ApiService.post(endpoint, requestData);
        this.items = res.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.currentResults = this.items.length;
        this.loading = false;
      }
    },
    async askForMoreResults() {
      const endpoint = "Ecommerce/GetAnagsProspect/";
      const requestData = {
        take: 10,
        skip: this.currentResults,
        ricerca: this.filter ? this.filter : "",
        regione:
          this.currentFiltroClienti.regione === "all"
            ? ""
            : this.currentFiltroClienti.regione,
        provincia:
          this.currentFiltroClienti.provincia === "all"
            ? ""
            : this.currentFiltroClienti.provincia,
        citta:
          this.currentFiltroClienti.citta === "all"
            ? ""
            : this.currentFiltroClienti.citta,
        idAgente:
          this.currentFiltroClienti.agente !== null
            ? this.currentFiltroClienti.agente
            : 0,
      };

      this.loading = true;

      try {
        const res = await ApiService.post(endpoint, requestData);
        this.items = this.items.concat(res.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.currentResults = this.items.length;
        this.loading = false;
      }
    },
    gotoDettagliCliente(cliente) {
      this.$router.push({
        name: "DettagliCliente",
        params: {
          idCliente: cliente.id,
        },
      });
    },
    async openModalActualClient(item) {
      const modal = await modalController.create({
        component: ModalActualClient,
        cssClass: "my-custom-class",
        componentProps: { client: item },
      });

      return modal.present();
    },
  },
  computed: {
    ...mapGetters("user", {
      userData: "getUserData",
    }),
  },
};
</script>
